/* these make sure it can work in any text element */
.inline-text_copy--active,
.inline-text_input--active {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 1px solid #262A4C;
  outline: none;
  display: block;
  min-width: 10px;
  min-height: 50px;
}

.inline-text_copy--active {
  cursor: pointer;
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  border-bottom: 1px solid #262A4C;
  text-align: left;
}
